<template>
  <v-container
    fluid
    class="white-background client-service-forms abb-form"
    :class="{ 'pointer-events-none': serviceForm.is_filled }"
  >
    <v-row>
      <v-col class="py-0" md="6">
        <table class="width-100 table-bordered">
          <tbody>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="200">
                Customer Name
              </td>
              <td>
                <v-text-field
                  v-model="formData.customer_name"
                  dense
                  filled
                  label="Customer Name"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="200">
                Site Location
              </td>
              <td>
                <v-text-field
                  v-model="formData.site_location"
                  dense
                  filled
                  label="Site Location"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="200">
                Site Address
              </td>
              <td>
                <v-text-field
                  v-model="formData.site_address"
                  dense
                  filled
                  label="Site Address"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="200">
                Contact Name
              </td>
              <td>
                <v-text-field
                  v-model="formData.contact_name"
                  dense
                  filled
                  label="Contact Name"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="200">
                Contact No
              </td>
              <td>
                <v-text-field
                  v-model="formData.contact_no"
                  dense
                  filled
                  label="Contact No"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
      <v-col class="py-0" md="6">
        <table class="width-100 table-bordered">
          <tbody>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="200">Date</td>
              <td>
                <DatePicker
                  v-model="formData.date"
                  solo
                  :defaultDate.sync="formData.date"
                ></DatePicker>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="200">
                Maintenance Plan
              </td>
              <td>
                <v-text-field
                  v-model="formData.maintenance_plan"
                  dense
                  filled
                  label="Maintenance Plan"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="200">
                Equipment ID
              </td>
              <td>
                <v-text-field
                  v-model="formData.equipment_id"
                  dense
                  filled
                  label="Equipment ID"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="200">
                Equipment Model
              </td>
              <td>
                <v-text-field
                  v-model="formData.equipment_model"
                  dense
                  filled
                  label="Equipment Model"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="200">
                Frame Serial No
              </td>
              <td>
                <v-text-field
                  v-model="formData.frame_serial_no"
                  dense
                  filled
                  label="Frame Serial No"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
      <v-col class="py-0" md="6">
        <table class="width-100 table-bordered my-8">
          <tbody>
            <tr class="light-gray-background">
              <td
                class="font-weight-500 font-size-16 p-2"
                width="500"
                align="left"
              >
                Input
              </td>
              <td class="font-weight-500 font-size-16 p-2" align="center">
                L1
              </td>
              <td class="font-weight-500 font-size-16 p-2" align="center">
                L2
              </td>
              <td class="font-weight-500 font-size-16 p-2" align="center">
                L3
              </td>
            </tr>
            <tr>
              <td
                class="font-weight-500 font-size-16 p-2"
                width="500"
                align="left"
              >
                Rectifier Voltage (V)
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model="formData.input.rectifier_voltage.l1"
                  dense
                  filled
                  label="L1"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model="formData.input.rectifier_voltage.l2"
                  dense
                  filled
                  label="L2"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model="formData.input.rectifier_voltage.l3"
                  dense
                  filled
                  label="L3"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td
                class="font-weight-500 font-size-16 p-2"
                width="500"
                align="left"
              >
                Bypass Voltage (V)
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model="formData.input.bypass_voltage.l1"
                  dense
                  filled
                  label="L1"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model="formData.input.bypass_voltage.l2"
                  dense
                  filled
                  label="L2"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model="formData.input.bypass_voltage.l3"
                  dense
                  filled
                  label="L3"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td
                class="font-weight-500 font-size-16 p-2"
                width="500"
                align="left"
              >
                Bypass Frequency (Hz)
              </td>
              <td colspan="3" class="font-weight-500 font-size-16">
                <v-text-field
                  v-model="formData.input.bypass_frequency.value"
                  dense
                  filled
                  label="Bypass Frequency (Hz)"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
          </tbody>
        </table>
        <table class="width-100 table-bordered my-8">
          <tbody>
            <tr class="light-gray-background">
              <td class="font-weight-500 font-size-16 p-2" width="250">
                Output
              </td>
              <td class="font-weight-500 font-size-16 p-2" align="center">
                L1
              </td>
              <td class="font-weight-500 font-size-16 p-2" align="center">
                L2
              </td>
              <td class="font-weight-500 font-size-16 p-2" align="center">
                L3
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="250">
                Output Voltage (V)
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model="formData.output.output_voltage.l1"
                  dense
                  filled
                  label="L1"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model="formData.output.output_voltage.l2"
                  dense
                  filled
                  label="L2"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model="formData.output.output_voltage.l3"
                  dense
                  filled
                  label="L3"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="250">
                Output Current (A)
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model="formData.output.output_current.l1"
                  dense
                  filled
                  label="L1"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model="formData.output.output_current.l2"
                  dense
                  filled
                  label="L2"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model="formData.output.output_current.l3"
                  dense
                  filled
                  label="L3"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="250">
                Output Frequency (Hz)
              </td>
              <td colspan="3" class="font-weight-500 font-size-16">
                <v-text-field
                  v-model="formData.output.output_frequency.value"
                  dense
                  filled
                  label="Output Frequency (Hz)"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="250">
                Active Output Power (kW)
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model="formData.output.active_output_power.l1"
                  dense
                  filled
                  label="L1"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model="formData.output.active_output_power.l2"
                  dense
                  filled
                  label="L2"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model="formData.output.active_output_power.l3"
                  dense
                  filled
                  label="L3"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="250">
                Reactive OP Power (kVAr)
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model="formData.output.reactive_op_power.l1"
                  dense
                  filled
                  label="L1"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model="formData.output.reactive_op_power.l2"
                  dense
                  filled
                  label="L2"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model="formData.output.reactive_op_power.l3"
                  dense
                  filled
                  label="L3"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="250">
                Apparent OP Power (kVA)
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model="formData.output.apparent_op_power.l1"
                  dense
                  filled
                  label="L1"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model="formData.output.apparent_op_power.l2"
                  dense
                  filled
                  label="L2"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model="formData.output.apparent_op_power.l3"
                  dense
                  filled
                  label="L3"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="250">
                Output Power (%)
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model="formData.output.output_power.l1"
                  dense
                  filled
                  label="L1"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model="formData.output.output_power.l2"
                  dense
                  filled
                  label="L2"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model="formData.output.output_power.l3"
                  dense
                  filled
                  label="L3"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
          </tbody>
        </table>
        <table class="width-100 table-bordered my-8">
          <tbody>
            <tr class="light-gray-background">
              <td class="font-weight-500 font-size-16 p-2" width="250">
                Module temperature
              </td>
              <td class="font-weight-500 font-size-16 p-2" align="center">
                BST
              </td>
              <td class="font-weight-500 font-size-16 p-2" align="center">
                INV
              </td>
              <td class="font-weight-500 font-size-16 p-2" align="center">
                AMB
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="250">
                Degree (˚C )
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model="formData.module_temperature.degree.bst"
                  dense
                  filled
                  label="BST"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model="formData.module_temperature.degree.inv"
                  dense
                  filled
                  label="INV"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model="formData.module_temperature.degree.amb"
                  dense
                  filled
                  label="AMB"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
          </tbody>
        </table>
        <table class="width-100 table-bordered my-8">
          <tbody>
            <tr class="light-gray-background">
              <td class="font-weight-500 font-size-16 p-2" width="250">
                Output Filter
              </td>
              <td class="font-weight-500 font-size-16 p-2" align="center">
                L1
              </td>
              <td class="font-weight-500 font-size-16 p-2" align="center">
                L2
              </td>
              <td class="font-weight-500 font-size-16 p-2" align="center">
                L3
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="250">
                Current (A)
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model="formData.output_filter.current.l1"
                  dense
                  filled
                  label="L1"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model="formData.output_filter.current.l2"
                  dense
                  filled
                  label="L2"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model="formData.output_filter.current.l3"
                  dense
                  filled
                  label="L3"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
      <v-col class="py-0" md="6">
        <table class="width-100 table-bordered my-8">
          <tbody>
            <tr class="light-gray-background">
              <td class="font-weight-500 font-size-16 p-2" width="250">
                PM serial number
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model="formData.pm_1_serial_number.pm_1_serial_number"
                  dense
                  filled
                  label="PM serial number"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="250">
                Manufacturing date
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model="formData.pm_1_serial_number.manufacturing_date"
                  dense
                  filled
                  label="Manufacturing date"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="250">
                {{ getCustomerServiceLabel(svfield) }}
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model="
                    formData.pm_1_serial_number.module_eprom_version
                  "
                  dense
                  filled
                  :label="getCustomerServiceLabel(svfield)"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="250">
                Hardware Version
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model="formData.pm_1_serial_number.hardware_version"
                  dense
                  filled
                  label="Hardware Version"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
          </tbody>
        </table>
        <table class="width-100 table-bordered my-8">
          <tbody>
            <tr class="light-gray-background">
              <td class="font-weight-500 font-size-16 p-2" width="250">
                Battery Bank
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model="formData.battery_bank.battery_bank"
                  dense
                  filled
                  label="Battery Bank"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="250">
                Battery Model
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model="formData.battery_bank.battery_model"
                  dense
                  filled
                  label="Battery Model"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="250">
                No of Block
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model="formData.battery_bank.no_of_block"
                  dense
                  filled
                  label="No of Block"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="250">
                No of Bank
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model="formData.battery_bank.no_of_bank"
                  dense
                  filled
                  label="No of Bank"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
          </tbody>
        </table>
        <table class="width-100 table-bordered my-8">
          <tbody>
            <tr class="light-gray-background">
              <td class="font-weight-500 font-size-16 p-2" width="250">
                Rectifier/Charger
              </td>
              <td class="font-weight-500 font-size-16 p-2" align="center">
                Positive
              </td>
              <td class="font-weight-500 font-size-16 p-2" align="center">
                Negative
              </td>
              <td class="font-weight-500 font-size-16 p-2" align="center">-</td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="250">
                Charger Volt (Vdc)
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model="
                    formData.rectifier_charger.charger_volt.positive
                  "
                  dense
                  filled
                  label="Positive"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model="
                    formData.rectifier_charger.charger_volt.negative
                  "
                  dense
                  filled
                  label="Negative"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model="formData.rectifier_charger.charger_volt.extra"
                  dense
                  filled
                  label="-"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="250">
                DC Bus Volt (Vdc)
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model="formData.rectifier_charger.dc_bus_volt.positive"
                  dense
                  filled
                  label="Positive"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model="formData.rectifier_charger.dc_bus_volt.negative"
                  dense
                  filled
                  label="Negative"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model="formData.rectifier_charger.dc_bus_volt.extra"
                  dense
                  filled
                  label="-"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="250">
                DC Amp (A)
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model="formData.rectifier_charger.dc_amp.positive"
                  dense
                  filled
                  label="Positive"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model="formData.rectifier_charger.dc_amp.negative"
                  dense
                  filled
                  label="Negative"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model="formData.rectifier_charger.dc_amp.extra"
                  dense
                  filled
                  label="-"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
          </tbody>
        </table>
        <table class="width-100 table-bordered my-8">
          <tbody>
            <tr class="light-gray-background">
              <td class="font-weight-500 font-size-16 p-2" width="250">
                Battery
              </td>
              <td class="font-weight-500 font-size-16 p-2" align="center">
                Postive
              </td>
              <td class="font-weight-500 font-size-16 p-2" align="center">
                Negative
              </td>
              <td class="font-weight-500 font-size-16 p-2" align="center">-</td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="250">
                Battery Voltages (Vdc)
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model="formData.battery.battery_voltages.positive"
                  dense
                  filled
                  label="Positive"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model="formData.battery.battery_voltages.negative"
                  dense
                  filled
                  label="Negative"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model="formData.battery.battery_voltages.extra"
                  dense
                  filled
                  label="-"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="250">
                DC Ripple (mV)
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model="formData.battery.dc_ripple.positive"
                  dense
                  filled
                  label="Positive"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model="formData.battery.dc_ripple.negative"
                  dense
                  filled
                  label="Negative"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model="formData.battery.dc_ripple.extra"
                  dense
                  filled
                  label="-"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td class="font-weight-500 font-size-16 p-2" width="250">
                DC Ripple Amp (mA)
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model="formData.battery.dc_ripple_amp.positive"
                  dense
                  filled
                  label="Positive"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model="formData.battery.dc_ripple_amp.negative"
                  dense
                  filled
                  label="Negative"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model="formData.battery.dc_ripple_amp.extra"
                  dense
                  filled
                  label="-"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
      <v-col class="py-0" md="6">
        <table class="width-100 table-bordered">
          <tbody>
            <tr class="light-gray-background">
              <td
                class="font-weight-500 font-size-16 p-2"
                align="center"
                colspan="3"
              >
                Physical Check List v Normal / x Follow-up with require
              </td>
            </tr>
            <tr class="light-gray-background">
              <td class="font-weight-500 font-size-16 p-2" width="60">Item</td>
              <td class="font-weight-500 font-size-16 p-2">Descriptions</td>
              <td class="font-weight-500 font-size-16 p-2">Status</td>
            </tr>
            <tr
              v-for="(row, index) in formData.physical_check_list_left"
              :key="index"
            >
              <td class="font-weight-500 font-size-16 p-2" width="60">
                {{ row.item }}
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model="row.description"
                  dense
                  filled
                  label="Descriptions"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-500 font-size-16" align="center">
                <v-checkbox
                  dense
                  v-model="row.status"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  color="cyan"
                  hide-details
                  class="m-0 mx-4"
                ></v-checkbox>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
      <v-col class="py-0" md="6">
        <table class="width-100 table-bordered">
          <tbody>
            <tr class="light-gray-background">
              <td
                class="font-weight-500 font-size-16 p-2"
                align="center"
                colspan="3"
              >
                Physical Check List v Normal / x Follow-up with require
              </td>
            </tr>
            <tr class="light-gray-background">
              <td class="font-weight-500 font-size-16 p-2" width="60">Item</td>
              <td class="font-weight-500 font-size-16 p-2">Descriptions</td>
              <td class="font-weight-500 font-size-16 p-2">Status</td>
            </tr>
            <tr
              v-for="(row, index) in formData.physical_check_list_right"
              :key="index"
            >
              <td class="font-weight-500 font-size-16 p-2" width="60">
                {{ row.item }}
              </td>
              <td class="font-weight-500 font-size-16">
                <v-text-field
                  v-model="row.description"
                  dense
                  filled
                  label="Descriptions"
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
              </td>
              <td class="font-weight-500 font-size-16" align="center">
                <v-checkbox
                  dense
                  v-model="row.status"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  color="cyan"
                  hide-details
                  class="m-0 mx-4"
                ></v-checkbox>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
      <v-col class="py-0" md="12">
        <table class="width-100 table-bordered my-8">
          <tbody>
            <tr class="light-gray-background">
              <td class="font-weight-500 font-size-16 p-2">Remarks</td>
            </tr>
            <tr>
              <td>
                <v-textarea
                  v-model="formData.remarks_and_parts_replacement"
                  auto-grow
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  dense
                  filled
                  color="cyan"
                  label="Remarks"
                  solo
                  flat
                  row-height="25"
                ></v-textarea>
              </td>
            </tr>
          </tbody>
        </table>
        <table class="width-100 table-bordered">
          <tbody>
            <tr class="light-gray-background">
              <td class="font-weight-500 font-size-16 p-2">
                Comments and Recommendation
              </td>
            </tr>
            <tr>
              <td>
                <v-textarea
                  v-model="formData.comments_and_recommendation"
                  auto-grow
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  dense
                  filled
                  color="cyan"
                  label="Comments and Recommendation"
                  solo
                  flat
                  row-height="25"
                ></v-textarea>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
      <v-col v-if="false" class="py-0" md="6">
        <table class="width-100 mt-8">
          <tbody>
            <tr>
              <td height="100" colspan="2" id="engineer-signature">
                <div class="engineer-signature custom-border-grey-dashed">
                  <canvas ref="engineersignature"></canvas>
                </div>
                <p class="mt-2 mx-2 mb-0 text-right">
                  <v-btn
                    content="Click here to clear signature"
                    v-tippy="{ arrow: true, placement: 'top' }"
                    icon
                    small
                    class="float-left"
                    v-on:click="engineerSignature.clear()"
                  >
                    <v-icon>mdi-close-circle-outline</v-icon>
                  </v-btn>
                  Service Engineer Name/Signature
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
      <v-col v-if="false" class="py-0" md="6">
        <table class="width-100 mt-8">
          <tbody>
            <tr>
              <td height="100" colspan="2" id="customer-signature">
                <div class="customer-signature custom-border-grey-dashed">
                  <canvas ref="customersignature"></canvas>
                </div>
                <p class="mt-2 mx-2 mb-0 text-right">
                  <v-btn
                    content="Click here to clear signature"
                    v-tippy="{ arrow: true, placement: 'top' }"
                    icon
                    small
                    class="float-left"
                    v-on:click="customerSignature.clear()"
                  >
                    <v-icon>mdi-close-circle-outline</v-icon>
                  </v-btn>
                  Customer Name/Signature
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DatePicker from "@/view/pages/partials/Datepicker";
/*import SignaturePad from "signature_pad";*/
import { ServiceFormEventBus } from "@/core/lib/service.form.lib";
import { PATCH, QUERY } from "@/core/services/store/request.module";
import ObjectPath from "object-path";

export default {
  props: {
    detail: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    serviceForm: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    contract: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    customer: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    property: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    billing: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    contactPerson: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
  },
  data: () => {
    return {
      pageLoading: true,
      svfield: "control_version",
      svfields: [
        {
          text: "Control Version",
          value: "control_version",
        },
        {
          text: "Module EPROM Version",
          value: "module_eprom_version",
        },
        {
          text: "Rectifier DSP Firmware",
          value: "rectifier_dsp_firmware",
        },
      ],
      engineerSignature: new Object(),
      customerSignature: new Object(),
      formData: new Object({
        barcode: null,
        customer_name: null,
        site_location: null,
        site_address: null,
        contact_name: null,
        contact_no: null,
        date: null,
        maintenance_plan: null,
        equipment_id: null,
        equipment_model: null,
        frame_serial_no: null,
        remarks_and_parts_replacement: null,
        comments_and_recommendation: null,
        customer_signature: null,
        engineer_signature: null,
        input: {
          rectifier_voltage: {
            l1: null,
            l2: null,
            l3: null,
          },
          bypass_voltage: {
            l1: null,
            l2: null,
            l3: null,
          },
          bypass_frequency: {
            value: null,
          },
        },
        output: {
          output_voltage: {
            l1: null,
            l2: null,
            l3: null,
          },
          output_current: {
            l1: null,
            l2: null,
            l3: null,
          },
          output_frequency: {
            value: null,
          },
          active_output_power: {
            l1: null,
            l2: null,
            l3: null,
          },
          reactive_op_power: {
            l1: null,
            l2: null,
            l3: null,
          },
          apparent_op_power: {
            l1: null,
            l2: null,
            l3: null,
          },
          output_power: {
            l1: null,
            l2: null,
            l3: null,
          },
        },
        module_temperature: {
          degree: {
            bst: null,
            inv: null,
            amb: null,
          },
        },
        output_filter: {
          current: {
            l1: null,
            l2: null,
            l3: null,
          },
        },
        pm_1_serial_number: {
          pm_1_serial_number: null,
          manufacturing_date: null,
          module_eprom_version: null,
          hardware_version: null,
        },
        battery_bank: {
          battery_bank: null,
          battery_model: null,
          no_of_block: null,
          no_of_bank: null,
        },
        rectifier_charger: {
          charger_volt: {
            positive: null,
            negative: null,
            extra: null,
          },
          dc_bus_volt: {
            positive: null,
            negative: null,
            extra: null,
          },
          dc_amp: {
            positive: null,
            negative: null,
            extra: null,
          },
        },
        battery: {
          battery_voltages: {
            positive: null,
            negative: null,
            extra: null,
          },
          dc_ripple: {
            positive: null,
            negative: null,
            extra: null,
          },
          dc_ripple_amp: {
            positive: null,
            negative: null,
            extra: null,
          },
        },
        physical_check_list_left: [
          {
            item: "a.",
            description: "Display Screen",
            status: null,
          },
          {
            item: "b.",
            description: "Axial/Blower Fan",
            status: null,
          },
          {
            item: "c.",
            description: "Capacitor",
            status: null,
          },
          {
            item: "d.",
            description: "PCB Board",
            status: null,
          },
        ],
        physical_check_list_right: [
          {
            item: "e.",
            description: "Physical unit",
            status: null,
          },
          {
            item: "f.",
            description: "Others, if any",
            status: null,
          },
          {
            item: "g.",
            description: null,
            status: null,
          },
          {
            item: "h.",
            description: null,
            status: null,
          },
        ],
      }),
    };
  },
  components: {
    DatePicker,
  },
  watch: {
    formData: {
      deep: true,
      immediate: true,
      handler(param) {
        console.log({ param });
      },
    },
  },
  methods: {
    updatePreInformation() {
      const _this = this;

      try {
        _this.formData = ObjectPath.get(_this.serviceForm, "attributes");

        _this.formData.barcode = ObjectPath.get(_this.serviceForm, "barcode");

        _this.pageLoading = false;
      } catch (error) {
        _this.pageLoading = false;
      }
    },
    saveServiceForm(action, product) {
      const _this = this;

      ServiceFormEventBus.$emit("start:loader");

      let param = _this.formData;

      /*if (_this.engineerSignature) {
        param.engineer_signature = _this.engineerSignature.toDataURL();
      }
      if (_this.customerSignature) {
        param.customer_signature = _this.customerSignature.toDataURL();
      }*/

      _this.$store
        .dispatch(PATCH, {
          url:
            "job/" +
            _this.detail.id +
            "/visit/" +
            _this.serviceForm.visit.id +
            "/service-form/" +
            _this.serviceForm.id,
          data: { attributes: param, action, product },
        })
        .then(({ data }) => {
          ServiceFormEventBus.$emit("save:service-form", data);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          ServiceFormEventBus.$emit("close:loader");
        });
    },
    getCustomerServiceLabel(value) {
      console.log({ svfields: this.svfields });
      console.log({ svfield: this.svfield });
      console.log({ value });
      const output = this.lodash.find(this.svfields, { value });
      return ObjectPath.get(output, "text");
    },
    getCustomerServiceForm() {
      const _this = this;
      const { customer } = this.customer;
      _this.$store
        .dispatch(QUERY, {
          url: "customer/service-forms",
          data: { customer },
        })
        .then(({ data }) => {
          const { customer_service_forms } = data;
          const serviceform = _this.lodash.find(customer_service_forms, {
            id: 1,
          });
          _this.svfield = ObjectPath.get(serviceform, "field");
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
  },
  beforeDestroy() {
    ServiceFormEventBus.$off("start:save-service-form");
    ServiceFormEventBus.$off("update:service-form-product");
  },
  mounted() {
    const _this = this;

    // _this.getCustomerServiceForm();

    ServiceFormEventBus.$on("start:save-service-form", (param, product) => {
      _this.saveServiceForm(param, product);
    });

    ServiceFormEventBus.$on("update:service-form-product", (param) => {
      _this.formData.maintenance_plan = ObjectPath.get(
        _this.contract,
        "contract_type"
      );
      _this.formData.equipment_id = ObjectPath.get(param, "name");
      _this.formData.equipment_model = ObjectPath.get(param, "brand");
      _this.formData.frame_serial_no = ObjectPath.get(
        param,
        "pm_one_serial_number"
      );
      _this.formData.pm_1_serial_number.pm_1_serial_number = ObjectPath.get(
        param,
        "serial_number"
      );
      _this.formData.pm_1_serial_number.manufacturing_date = ObjectPath.get(
        param,
        "manufacturing_date"
      );
      _this.formData.pm_1_serial_number.module_eprom_version = ObjectPath.get(
        param,
        "control_version"
      );
      _this.formData.pm_1_serial_number.hardware_version = ObjectPath.get(
        param,
        "system_version"
      );
      _this.formData.battery_bank.battery_bank = ObjectPath.get(
        param,
        "battery_bank"
      );
      _this.formData.battery_bank.battery_model = ObjectPath.get(
        param,
        "battery_model"
      );
      _this.formData.battery_bank.no_of_block = ObjectPath.get(
        param,
        "no_of_block"
      );
      _this.formData.battery_bank.no_of_bank = ObjectPath.get(
        param,
        "no_of_bank"
      );
    });

    _this.updatePreInformation();

    setTimeout(function () {
      _this.$nextTick(() => {
        /*let ecanvas = _this.$refs["engineersignature"];
        let eparentDiv = document.getElementById("engineer-signature");
        let eparentWidth = eparentDiv.offsetWidth;
        ecanvas.setAttribute("width", eparentWidth);
        _this.engineerSignature = new SignaturePad(ecanvas);

        let ccanvas = _this.$refs["customersignature"];
        let cparentDiv = document.getElementById("customer-signature");
        let cparentWidth = cparentDiv.offsetWidth;
        ccanvas.setAttribute("width", cparentWidth);
        _this.customerSignature = new SignaturePad(ccanvas);*/
      });
    }, 1000);
  },
};
</script>
